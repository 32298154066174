var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("color-input-row", {
        attrs: { "line-number": "1", title: "Background color" },
        model: {
          value: _vm.value.backgroundColor,
          callback: function ($$v) {
            _vm.$set(_vm.value, "backgroundColor", $$v)
          },
          expression: "value.backgroundColor",
        },
      }),
      _c("v-divider"),
      _c("color-input-row", {
        attrs: { "line-number": "2", title: "Spinner" },
        model: {
          value: _vm.value.spinner,
          callback: function ($$v) {
            _vm.$set(_vm.value, "spinner", $$v)
          },
          expression: "value.spinner",
        },
      }),
      _c("v-divider"),
      _c("color-input-row", {
        attrs: { "line-number": "3", title: "Language icon" },
        model: {
          value: _vm.value.languageIcon,
          callback: function ($$v) {
            _vm.$set(_vm.value, "languageIcon", $$v)
          },
          expression: "value.languageIcon",
        },
      }),
      _c("v-divider"),
      _c("color-input-row", {
        attrs: { "line-number": "4", title: "Text color" },
        model: {
          value: _vm.value.textColor,
          callback: function ($$v) {
            _vm.$set(_vm.value, "textColor", $$v)
          },
          expression: "value.textColor",
        },
      }),
      _c("v-divider"),
      _c("file-input-row", {
        attrs: {
          "line-number": "5",
          title: "Logo",
          "input-label": "Site Logo (svg preferred)",
          required: "",
        },
        model: {
          value: _vm.value.siteLogo,
          callback: function ($$v) {
            _vm.$set(_vm.value, "siteLogo", $$v)
          },
          expression: "value.siteLogo",
        },
      }),
      _c("v-divider"),
      _c("field-row", {
        attrs: {
          "line-number": "6",
          title: "Features item(s)",
          tooltip: "To set or edit feature items, go to hub.getgrab.com",
        },
      }),
      _c("v-divider"),
      _c(
        "multiple-fields-row",
        { attrs: { "line-number": "7" } },
        [
          _c("color-input-row", {
            attrs: { title: "Color", "hide-line-number": "" },
            model: {
              value: _vm.value.startButtonColor,
              callback: function ($$v) {
                _vm.$set(_vm.value, "startButtonColor", $$v)
              },
              expression: "value.startButtonColor",
            },
          }),
          _c("text-field-row", {
            attrs: {
              title: "Label",
              "input-label": "Start button label",
              "hide-line-number": "",
            },
            model: {
              value: _vm.value.startButtonText,
              callback: function ($$v) {
                _vm.$set(_vm.value, "startButtonText", $$v)
              },
              expression: "value.startButtonText",
            },
          }),
          _c("color-input-row", {
            attrs: { title: "Label color", "hide-line-number": "" },
            model: {
              value: _vm.value.startButtonTextColor,
              callback: function ($$v) {
                _vm.$set(_vm.value, "startButtonTextColor", $$v)
              },
              expression: "value.startButtonTextColor",
            },
          }),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "multiple-fields-row",
        { attrs: { "line-number": "8" } },
        [
          _c("color-input-row", {
            attrs: { title: "Background color", "hide-line-number": "" },
            model: {
              value: _vm.value.headerBackgroundColor,
              callback: function ($$v) {
                _vm.$set(_vm.value, "headerBackgroundColor", $$v)
              },
              expression: "value.headerBackgroundColor",
            },
          }),
          _c("color-input-row", {
            attrs: { title: "Text color", "hide-line-number": "" },
            model: {
              value: _vm.value.headerTextColor,
              callback: function ($$v) {
                _vm.$set(_vm.value, "headerTextColor", $$v)
              },
              expression: "value.headerTextColor",
            },
          }),
          _c("color-input-row", {
            attrs: { title: "Button color", "hide-line-number": "" },
            model: {
              value: _vm.value.headerButtonColor,
              callback: function ($$v) {
                _vm.$set(_vm.value, "headerButtonColor", $$v)
              },
              expression: "value.headerButtonColor",
            },
          }),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "multiple-fields-row",
        { attrs: { "line-number": "9" } },
        [
          _c("text-field-row", {
            attrs: { title: "Allergy copy", "hide-line-number": "" },
            model: {
              value: _vm.value.allergyCopy,
              callback: function ($$v) {
                _vm.$set(_vm.value, "allergyCopy", $$v)
              },
              expression: "value.allergyCopy",
            },
          }),
          _c("text-field-row", {
            attrs: { title: "Modal title", "hide-line-number": "" },
            model: {
              value: _vm.value.allergyModalTitle,
              callback: function ($$v) {
                _vm.$set(_vm.value, "allergyModalTitle", $$v)
              },
              expression: "value.allergyModalTitle",
            },
          }),
          _c("text-field-row", {
            attrs: { title: "Modal copy", "hide-line-number": "" },
            model: {
              value: _vm.value.allergyModalCopy,
              callback: function ($$v) {
                _vm.$set(_vm.value, "allergyModalCopy", $$v)
              },
              expression: "value.allergyModalCopy",
            },
          }),
        ],
        1
      ),
      _c("v-divider"),
      _c("color-input-row", {
        attrs: { "line-number": "11", title: "Link color" },
        model: {
          value: _vm.value.linkColor,
          callback: function ($$v) {
            _vm.$set(_vm.value, "linkColor", $$v)
          },
          expression: "value.linkColor",
        },
      }),
      _c("v-divider"),
      _c("text-field-row", {
        attrs: { "line-number": "12", title: "Caloric Information" },
        model: {
          value: _vm.value.caloricInformation,
          callback: function ($$v) {
            _vm.$set(_vm.value, "caloricInformation", $$v)
          },
          expression: "value.caloricInformation",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }