var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "pt-1 pb-0" },
    [
      _c(
        "v-col",
        _vm._b(
          { staticClass: "pa-1 text-center" },
          "v-col",
          _vm.lineNumberProps,
          false
        ),
        [
          _vm.lineNumber
            ? _c("line-number", { attrs: { label: _vm.lineNumber } })
            : _vm._e(),
        ],
        1
      ),
      _c("v-col", { staticClass: "pa-2" }, [_vm._t("default")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }