<template>
<div>
    <color-input-row
        line-number="1"
        title="Background color"
        v-model="value.backgroundColor"
    />
    <v-divider/>
    <color-input-row
        line-number="2"
        title="Spinner"
        v-model="value.spinner"
    />
    <v-divider/>
    <color-input-row
        v-model="value.languageIcon"
        line-number="3"
        title="Language icon"
    />
    <v-divider />
    <color-input-row
        v-model="value.textColor"
        line-number="4"
        title="Text color"
    />
    <v-divider />
    <file-input-row
        line-number="5"
        title="Logo"
        v-model="value.siteLogo"
        input-label="Site Logo (svg preferred)"
        required
    />
    <v-divider />
    <field-row
        line-number="6"
        title="Features item(s)"
        tooltip="To set or edit feature items, go to hub.getgrab.com"
    />
    <v-divider/>
    <multiple-fields-row
        line-number="7"
    >
        <color-input-row
            v-model="value.startButtonColor"
            title="Color"
            hide-line-number
        />
        <text-field-row
            v-model="value.startButtonText"
            title="Label"
            input-label="Start button label"
            hide-line-number
        />
        <color-input-row
            v-model="value.startButtonTextColor"
            title="Label color"
            hide-line-number
        />
    </multiple-fields-row>
    <v-divider />
    <multiple-fields-row
        line-number="8"
    >
        <color-input-row
            v-model="value.headerBackgroundColor"
            title="Background color"
            hide-line-number
        />
        <color-input-row
            v-model="value.headerTextColor"
            title="Text color"
            hide-line-number
        />
        <color-input-row
            v-model="value.headerButtonColor"
            title="Button color"
            hide-line-number
        />
    </multiple-fields-row>
    <v-divider/>
    <multiple-fields-row
        line-number="9"
    >
        <text-field-row
            v-model="value.allergyCopy"
            title="Allergy copy"
            hide-line-number
        />
        <text-field-row
            v-model="value.allergyModalTitle"
            title="Modal title"
            hide-line-number
        />
        <text-field-row
            v-model="value.allergyModalCopy"
            title="Modal copy"
            hide-line-number
        />
    </multiple-fields-row>
    <v-divider/>
    <color-input-row
        v-model="value.linkColor"
        line-number="11"
        title="Link color"
    />
    <v-divider/>
    <text-field-row
        v-model="value.caloricInformation"
        line-number="12"
        title="Caloric Information"
    />
</div>
</template>

<script>
import LineNumber from "components/LineNumber";
import TextTooltip from "components/text_tooltip";
import FieldRow from "components/create_store/fields/FieldRow";
import ColorInputRow from "components/create_store/fields/ColorInputRow";
import TextFieldRow from "components/create_store/fields/TextFieldRow";
import CheckboxRow from "components/create_store/fields/CheckboxRow";
import FileInputRow from "components/create_store/fields/FileInputRow";
import MultipleFieldsRow from "components/create_store/fields/MultipleFieldsRow";

export default {
    name: "LaunchScreenSingleImage",
    components: {
        FileInputRow,
        LineNumber,
        TextTooltip,
        FieldRow,
        ColorInputRow,
        TextFieldRow,
        CheckboxRow,
        MultipleFieldsRow,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        lineNumberProps: {
            type: Object,
            required: true,
        }
    }
}
</script>

<style scoped lang="scss">
@import "/src/pages/create_store/wizard/section";
</style>
