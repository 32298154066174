<template>
    <v-row class="pt-1 pb-0">
        <v-col v-bind="lineNumberProps" class="pa-1 text-center">
            <line-number v-if="lineNumber" :label="lineNumber" />
        </v-col>
        <v-col class="pa-2">
            <slot></slot>
        </v-col>
    </v-row>
</template>

<script>
import LineNumber from "components/LineNumber";

export default {
    name: "MultipleFieldsRow",
    components: {
        LineNumber
    },
    props: {
        lineNumber: {
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            lineNumberProps: {
                md: '1',
                sm: '2',
                lg: '1',
            },
        };
    }
}
</script>

<style scoped lang="scss">
.v-input--selection-controls {
    margin-top: 0 !important;
}
</style>

